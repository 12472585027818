import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "card h-100 shadow-sm" }
const _hoisted_3 = { class: "card-body text-center" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "card-text text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnList, (column) => {
      return (_openBlock(), _createElementBlock("div", {
        key: column.id,
        class: "col-4 mb-4"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: column.avatar,
              alt: column.title,
              class: "rounded-circle border border-light w-25 my-3"
            }, null, 8, _hoisted_4),
            _createElementVNode("h5", _hoisted_5, _toDisplayString(column.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(column.description), 1),
            _createVNode(_component_router_link, {
              to: `/column/${column.id}`,
              class: "btn btn-outline-primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode("进入专栏")
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ])
      ]))
    }), 128))
  ]))
}